<template>
  <div>
    <HomeOffersGroupSlider
      :query="{ 'store_ids[]': [store?.id], limit: 1000 , branch_id: currentBranchId }"
    />

    <MaterialsGrid
      class="mevcut-container min-h-screen mb-5"
      :fetch-route="domainRoutes.materialsByServer"
      :fetch-params="{ offersOnly: true, store_id: store?.id , branch_id: currentBranchId }"
    />
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'

const { url } = useDomainHost()
const { store } = useDomainState()
const { t } = useI18n()
const domainRoutes = getAllRoutes().materialsRoutes.domainRoutes
const currentBranchId = useBranchId()
useServerSeoMeta(
  useOgMeta(
    url + '/offers',
    t('offers_discounts'),
    store.value?.about,
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('offers_discounts'),
  link: [useSeoCanonical(url + '/offers')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, store.value?.name, url),
      useSeoBreadcrumbItem(2, t('offers_discounts'), url + '/offers')
    ])
  ]
})
</script>
